<template>
    <img
        :src="src"
        :alt="alt"
        :loading="loading"
        :decoding="decoding"
    />
</template>

<script>
export default {
    name: "BaseImage",
    props: {
        src: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            required: true
        },
        loading: {
            type: String,
            default: "lazy"
        },
        decoding: {
            type: String,
            defatul: "async"
        }
    }
}
</script>

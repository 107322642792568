<template>
    <ol
        v-if="!!back_info.title || (!!fallback && !!fallback.title)"
        class="text-gray page-back"
    >
        <li class="page-back-item">
            <base-link
                v-if="!!back_info.title"
                :href="back_info.id"
                @click.native.prevent.stop="pop(true)"
            >
                <open-icon glyph="chevron-left" /> {{ back_info.title }}
            </base-link>
            <base-link
                v-else-if="(!!fallback && !!fallback.title)"
                :href="fallback.href"
            >
                <open-icon glyph="chevron-left" /> {{ fallback.title }}
            </base-link>
        </li>
    </ol>
</template>

<script>
import { mapGetters } from "vuex"

import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "PageBack",
    components: {
        BaseLink,
        OpenIcon
    },
    mounted () {
        // window.addEventListener("popstate", this.pop_history)
        this.reset()
    },
    beforeDestroy () {
        // window.removeEventListener("popstate", this.pop_history)
    },
    watch: {
        title: "title_changed"
    },
    methods: {
        reset () {
            if(this.isRoot) this.$store.dispatch("HISTORY_POP", { all: true })
            this.title_changed()
        },
        title_changed () {
            if(this.title) this.$store.dispatch("HISTORY_PUSH", { title: this.title })
        },
        pop (go_back) {
            this.$store.dispatch("HISTORY_POP", {})
            if(go_back) this.$router.go(-1)
        }
        /*
        pop_history () {
            console.log("pop_history")
        }
        */
    },
    computed: {
        ...mapGetters(["history_back_info"]),
        back_info () {
            return this.history_back_info()
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        isRoot: {
            type: Boolean,
            default: false
        },
        fallback: {
            type: Object, // { title, href: (string | object) }
            default: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

ol.page-back {
    & > li.page-back-item {
        list-style: none;

        & + li.page-back-item:before {
            content: "-";
            display: inline-block;
            padding-right: 0.5rem;
            color: $brand-color-dark-gray;
        }
    }
}
</style>

<template>
    <modal-dialog
        id="authentication"
        :show.sync="shown"
        :dismissable="false"
        class="authentication"
    >
        <login
            v-if="current_panel_id==='login'"
            v-model="email"
            :label-less="labelLess"
            @goto="current_panel_id = $event"
        />
        <lost-password
            v-if="current_panel_id==='password'"
            v-model="email"
            :label-less="labelLess"
            @goto="current_panel_id=$event"
        />
        <reset-password
            v-if="current_panel_id==='reset'"
            v-model="email"
            :label-less="labelLess"
            @goto="current_panel_id=$event"
        />
        <signup
            v-if="current_panel_id==='signup'"
            v-model="email"
            :label-less="labelLess"
            @goto="current_panel_id=$event"
        />
    </modal-dialog>
</template>

<script type="text/javascript">
import { mapState } from "vuex"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"

import Login from "./Login"
import LostPassword from "./LostPassword"
import ResetPassword from "./ResetPassword"
import Signup from "@/custom/dialogs/Authentication/Signup"
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"

export default {
    mixins: [profile_utilities],
    components: {
        Login,
        LostPassword,
        ResetPassword,
        Signup,
        ModalDialog
    },
    watch: {
        profile_id: "maybe_dismiss",
        "app_context.settings": "maybe_switch_panel",
        "login_request.panel_id": "maybe_switch_panel"
    },
    methods: {
        dismiss () {
            this.$store.dispatch("UNREQUEST_LOGIN")
        },
        maybe_dismiss () {
            if(this.profile_id) this.shown = false
        },
        maybe_switch_panel () {
            if(this.shown) {
                if(this.login_request.panel_id === true) {
                    this.current_panel_id = "login"
                } else this.current_panel_id = this.login_request.panel_id
            }
        }
    },
    computed: {
        ...mapState(["profile_id", "login_request", "app_context"]),
        shown: {
            get () {
                return !this.profile_id && !!this.login_request && !!this.login_request.panel_id
            },
            set (show) {
                if(!show) this.dismiss()
            }
        },
        can_signup () {
            return (!!window.location.search && !!window.location.search.match(/(?:\?|&)invitation=([^&]+)(?:&|$)/)) || (!!this.app_context && !!this.app_context.settings && !!this.app_context.settings.signups)
        }
    },
    props: {
        labelLess: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            current_panel_id: "",
            email: ""
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.modal.authentication {
    background: $body-bg;

    & > .modal-container {
        overflow: hidden;

        .modal-body {
            padding: 0;

            .card-header {
                .app-logo {
                    max-height: 3.5rem;
                    margin: 0 auto;
                }
            }
            .card-body {
                padding-top: $unit-8;
                padding-bottom: $unit-8;
            }
            & > .loader-container {
                text-align: center;

                & > .loader {
                    margin-top: 3rem;
                    margin-bottom: 3rem;
                }
            }
        }
        .modal-footer {
            text-align: center;
        }
    }
}
</style>

<template>
    <div style="height: 100%;">
        <div
            v-if="usesGeolocation && !geolocation_is_active"
            class="empty"
        >
            <div class="empty-icon">
                <open-icon glyph="map-marked-alt" size="5x" />
            </div>
            <p class="empty-title h5">
                {{ translate("Can not determine your current location") }}
            </p>
            <p class="empty-subtitle">
                {{ translate("Most likely, you declined to grant access at some point in the past. Follow the instructions on the following page to grant access manually:") }}
            </p>
            <div class="empty-action">
                <base-link
                    href="https://www.lifewire.com/denying-access-to-your-location-4027789"
                    class="btn btn-primary"
                >
                    {{ translate("How to grant access to my current location") }}
                </base-link>
            </div>
        </div>
        <div
            v-show="!usesGeolocation || !!geolocation_is_active"
            :class="{ selecting: !!selection }"
            class="nibnut-map"
            :style="{ height }"
        >
            <div
                ref="map"
                :style="{ height }"
            ></div>
            <template v-if="!!map">
                <map-marker
                    v-for="marker in markers"
                    :key="marker.id"
                    :marker="marker"
                    :realtor-id="profile.id"
                    :map="map"
                    @toggle-selection="$emit('toggle-selection', $event)"
                />
            </template>
        </div>
    </div>
</template>

<script>
import debounce from "lodash/debounce"

import string_utilities from "@/nibnut/mixins/StringUtilities"
import ui_utilities from "@/nibnut/mixins/UiUtilities"
import google_map_loader from "@/nibnut/components/Map/GoogleMapLoader"

import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"
import { map_settings, DEFAULT_ZOOM } from "./MapSettings"

import { MarkerClusterer } from "@googlemaps/markerclusterer"
import MapMarker from "./MapMarker"

let radius_button = null
let radius_control = null
let radius_circle = null
let radius_circle_listener = null

export default {
    name: "MapLoader",
    mixins: [string_utilities, ui_utilities, google_map_loader, addl_profile_utilities],
    components: {
        MapMarker,
        BaseLink,
        OpenIcon
    },
    mounted () {
        if(this.usesGeolocation && ("permissions" in navigator)) {
            navigator.permissions.query(
                { name: "geolocation" }
            ).then(found => {
                this.permission_handler = found
                this.permission_changed()
                this.recenter()
                this.permission_handler.addEventListener("change", this.permission_changed)
            })
        } else this.recenter()
    },
    onBeforeUnload () {
        if(this.permission_handler) this.permission_handler.removeEventListener("change", this.permission_changed)
    },
    watch: {
        listings: "build_markers",
        anchoredTo: "highlight"
    },
    methods: {
        permission_changed () {
            this.geolocation_is_active = (this.permission_handler.state !== "denied")
        },
        build_markers () {
            if(this.map) {
                const listings = [...this.listings]
                // if(this.anchoredTo) listings.push(this.anchoredTo)
                this.markers = listings.filter(listing => !!listing.latitude && !!listing.longitude).map(listing => {
                    let marker = this.markers.find(marker => marker._dg_listing.id === listing.id)
                    if(!marker) {
                        let url = null
                        const selected = this.selection && (this.selection.indexOf(listing.id) >= 0)
                        if(this.anchoredTo && (this.anchoredTo.id === listing.id)) url = "/img/pin_gray.png"
                        else if(selected) url = "/img/pin_blue.png"
                        else if(!listing.is_available) url = "/img/pin_red.png"
                        else url = "/img/pin_green.png"
                        // if(this.is_at_least_realtor_for_listing(listing)) url = listing.is_available ? "/img/pin_dg_active.png" : "/img/pin_dg_disabled.png"
                        marker = new window._nibnut_google_loader.maps.Marker({
                            map: this.map,
                            position: { lat: listing.latitude, lng: listing.longitude },
                            icon: {
                                url,
                                scaledSize: new window._nibnut_google_loader.maps.Size(36, 36)
                            },
                            title: this.address_one_line(listing),
                            _dg_listing: listing,
                            _selected: selected
                        })
                    }
                    return marker
                })

                this.refresh_visible_markers()
            }
        },
        refresh () {
            if(this.map && this.mapConfig && this.mapConfig.center && this.mapConfig.center.lat) {
                this.map.setOptions(this.mapConfig)
                this.build_markers()
            }
        },
        load_google_objects () {
            if(!window._nibnut_google_loader || !this.$refs.map) return
            this.map = new window._nibnut_google_loader.maps.Map(this.$refs.map, {
                ...map_settings,
                ...this.mapConfig
            })
            this.clusterer = new MarkerClusterer({ markers: [], map: this.map })

            const button = document.createElement("button")
            button.draggable = false
            button.type = "button"
            button.title = this.translate("Re-center the map")
            button.ariaLabel = button.title
            button.style.background = "none rgb(255, 255, 255)"
            button.style.border = "0px"
            button.style.margin = "10px"
            button.style.borderRadius = "2px"
            button.style.userSelect = "none"
            button.style.height = "40px"
            button.style.width = "40px"
            button.style.boxShadow = "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px"
            button.style.top = "0px"
            button.style.right = "0px"
            button.classList.add("gm-control-active", "btn")
            const icon = document.createElement("i")
            icon.classList.add("las", "la-crosshairs", "la-lg")
            button.appendChild(icon)
            button.addEventListener("click", () => {
                this.recenter()
            })
            this.map.controls[window._nibnut_google_loader.maps.ControlPosition.TOP_RIGHT].push(button)

            const group = document.createElement("div")
            group.classList.add("mb-3")
            // group.classList.add("form-group", "col-9", "mb-3")

            group.innerHTML = `
                <button
                    id="dg-radius-button"
                    type="button"
                    title="${this.translate("Set Radius")}"
                    aria-label="${this.translate("Set Radius")}"
                    class="btn"
                    style="background: none rgb(255, 255, 255); border: 0px; border-radius: 2px; user-select: none; box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;"
                >
                    ${this.translate("Set Radius")}
                </button>
                <div id="dg-radius-control" class="input-group d-none">
                    <input type="number" min="0" step="0.5" class="form-input" />
                    <span class="input-group-addon">m|km</span>
                    <button type="button" class="btn input-group-btn">
                        <i class="las la-times"></i>
                    </button>
                </div>
            `
            radius_button = group.querySelector("#dg-radius-button")
            radius_button.addEventListener("click", () => {
                this.set_radius()
            })
            radius_control = group.querySelector("#dg-radius-control")
            radius_control.querySelector("input").addEventListener("input", this.set_radius)
            radius_control.querySelector("button").addEventListener("click", () => {
                this.set_radius(null)
            })
            /*
            radius_button = document.createElement("button")
            radius_button.draggable = false
            radius_button.type = "button"
            radius_button.title = this.translate("Set Radius")
            radius_button.ariaLabel = radius_button.title
            radius_button.style.background = "none rgb(255, 255, 255)"
            radius_button.style.border = "0px"
            radius_button.style.borderRadius = "2px"
            radius_button.style.userSelect = "none"
            radius_button.style.boxShadow = "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px"
            radius_button.classList.add("gm-control-active", "btn")
            group.appendChild(radius_button)

            radius_control = document.createElement("div")
            */
            this.map.controls[window._nibnut_google_loader.maps.ControlPosition.BOTTOM_CENTER].push(group)

            /*
            radius_circle = new this.google.maps.Circle({
                strokeColor: "#c50802",
                strokeOpacity: 1,
                strokeWeight: 2,
                fillColor: "#c50802",
                fillOpacity: 0.35,
                center: this.map.getCenter(),
                radius: 0,
                draggable: true
            })
            */

            this.google.maps.event.addListener(this.map, "tilesloaded", this.refresh_visible_markers)
            /*
            this.google.maps.event.addListener(this.map, "zoom_changed", () => {
                console.log("DEBUG", { zoom: this.map.getZoom() })
            })
            */
            // this.google.maps.event.addListener(this.map, "idle", this.refresh_position)
            this.google.maps.event.addListener(this.map, "bounds_changed", this.update_bounds)
            this.refresh()
            this.recenter()
        },
        refresh_visible_markers: debounce(function () {
            this.clusterer.clearMarkers()
            const bounds = this.map.getBounds()
            this.visible_markers = this.markers.filter(marker => {
                return marker.getVisible() && !!bounds && bounds.contains(marker.getPosition())
            })
            if(this.current_position) {
                this.visible_markers.push(
                    new window._nibnut_google_loader.maps.Marker({
                        map: this.map,
                        position: this.current_position,
                        title: this.translate("Current Position"),
                        clickable: false
                    })
                )
            }
            this.clusterer.addMarkers(this.visible_markers)
        }, 500),
        refresh_position () {
            if(!this.map.getCenter()) this.recenter()
            else this.update_bounds()
        },
        set_radius: debounce(function (event) {
            if(event === undefined) {
                this.radius = this.max_radius
            } else if(event === null) {
                this.radius = 0
            } else {
                let radius = parseFloat(event.target.value) || 0
                if(this.max_radius > (5 * 1000)) radius *= 1000
                this.radius = radius
            }
            this.radius_ui()
            this.update_bounds()
        }, 500),
        radius_ui () {
            if(this.radius) {
                radius_button.classList.add("d-none")
                radius_control.classList.remove("d-none")
            } else {
                radius_button.classList.remove("d-none")
                radius_control.classList.add("d-none")
            }
        },
        refresh_radius (radius_center = null) {
            if(this.radius) {
                if(radius_circle) {
                    radius_circle.setMap(this.map)
                    radius_circle.setRadius(this.radius)
                } else {
                    radius_circle = new this.google.maps.Circle({
                        map: this.map,
                        strokeColor: "#c50802",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                        fillColor: "#c50802",
                        fillOpacity: 0.35,
                        center: radius_center || this.map.getCenter(),
                        radius: this.radius,
                        draggable: true
                    })
                    radius_circle_listener = radius_circle.addListener("dragend", this.update_bounds)
                }
            } else if(!!radius_circle && !!radius_circle.getMap()) {
                radius_circle.setMap(null)
                if(radius_circle_listener) {
                    this.google.maps.event.removeListener(radius_circle_listener)
                    radius_circle_listener = null
                }
                radius_circle = null
            }
        },
        recenter (zoom = DEFAULT_ZOOM) {
            if(!!this.map && ("geolocation" in navigator)) {
                const set_center = position => {
                    if(this.anchoredTo && this.anchoredTo.latitude && this.anchoredTo.longitude) position = { lat: this.anchoredTo.latitude, lng: this.anchoredTo.longitude }
                    this.map.setCenter(position)
                    this.map.setZoom(zoom)
                    this.update_bounds()
                }
                navigator.geolocation.getCurrentPosition(
                    position => {
                        this.current_position = { lat: position.coords.latitude, lng: position.coords.longitude }
                        set_center(this.current_position)
                    },
                    error => {
                        // https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
                        if(error.code !== error.PERMISSION_DENIED) console.error(error.message)
                        set_center({ lat: 49.16585171564027, lng: -123.93907450858039 })
                    },
                    { timeout: 60000 }
                )
            }
        },
        highlight () {
            this.recenter(17)
            if(this.anchoredTo && this.anchoredTo.latitude && this.anchoredTo.longitude) {
                setTimeout(() => {
                    const marker = this.markers.find(marker => marker._dg_listing.id === this.anchoredTo.id)
                    if(marker) this.google.maps.event.trigger(marker, "click")
                    this.scroll_to(this.$refs.map)
                }, 1000)
            }
        },
        update_bounds () {
            if(!this.map) return
            let tries = 0
            const read = () => {
                const bounds = this.map.getBounds()
                if(!bounds && (++tries <= 10)) setTimeout(read, 200)
                else if(bounds) {
                    const south_west = bounds.getSouthWest()
                    const north_east = bounds.getNorthEast()
                    const center = this.map.getCenter()
                    const radius_circle_center = radius_circle ? radius_circle.getCenter() : null
                    const area = {
                        xmin: `${south_west.lng()}`,
                        ymin: `${south_west.lat()}`,
                        xmax: `${north_east.lng()}`,
                        ymax: `${north_east.lat()}`,
                        center: { lat: `${center.lat()}`, lng: `${center.lng()}` },
                        radius_center: { lat: radius_circle_center ? `${radius_circle_center.lat()}` : 0, lng: radius_circle_center ? `${radius_circle_center.lng()}` : 0 },
                        radius: this.radius // 0 if full map, else in meters
                    }
                    if(this.radius) {
                        const max_radius = this.max_radius
                        const auto_set = this.radius > max_radius
                        this.radius = Math.min(this.radius, max_radius)

                        const input = radius_control.querySelector("input")
                        const input_addon = radius_control.querySelector(".input-group-addon")
                        input.max = max_radius
                        if(max_radius >= (5 * 1000)) {
                            input.min = 1
                            input_addon.innerHTML = "km"
                            if(max_radius >= (25 * 1000)) input.step = 5
                            else input.step = 1
                        } else {
                            input_addon.innerHTML = "m"
                            if(max_radius >= (1 * 1000)) input.step = 500
                            else if(max_radius >= 500) input.step = 50
                            else input.step = 10
                            input.min = input.step
                        }

                        const multiplier = (max_radius >= (5 * 1000)) ? 1000 : 1
                        let display_value = this.radius
                        if(auto_set) display_value = (Math.round(this.radius / (input.step * multiplier)) * (input.step * multiplier))
                        input.value = display_value / multiplier
                    }
                    this.refresh_radius()
                    this.$emit("input", {
                        area
                    })
                }
            }
            read()
        }
    },
    computed: {
        google () {
            return window._nibnut_google_loader
        },
        max_radius () {
            let max_radius = 1000
            if(this.map) {
                const bounds = this.map.getBounds()
                if(bounds) {
                    const south_west = bounds.getSouthWest()
                    const north_east = bounds.getNorthEast()
                    const area = {
                        xmin: south_west.lng(),
                        ymin: south_west.lat(),
                        xmax: north_east.lng(),
                        ymax: north_east.lat()
                    }

                    let distance = 0
                    if((area.xmax - area.xmin) < (area.ymax - area.ymin)) {
                        distance = this.google.maps.geometry.spherical.computeDistanceBetween({ lat: 0, lng: area.xmin }, { lat: 0, lng: area.xmax })
                    } else {
                        distance = this.google.maps.geometry.spherical.computeDistanceBetween({ lat: area.ymin, lng: 0 }, { lat: area.ymax, lng: 0 })
                    }
                    if(distance) {
                        max_radius = (distance / 2)
                        if(max_radius > (5 * 1000)) max_radius = Math.round((distance / 2) / 1000) * 1000
                        else if(max_radius > 1000) max_radius = Math.round((distance / 2) / 500) * 500
                        else max_radius = Math.round((distance / 2) / 10) * 10
                    }
                }
            }
            return max_radius
        }
    },
    props: {
        id: {
            type: String
        },
        listings: {
            type: Array,
            default () {
                return []
            }
        },
        anchoredTo: {
            type: Object,
            default () {
                return {}
            }
        },
        height: {
            type: String,
            default: "320px"
        },
        selection: {
            type: Array,
            default () {
                return null
            }
        },
        usesGeolocation: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            permission_handler: null,
            geolocation_is_active: false,
            google_map_libraries: ["geometry"],
            radius: null,
            map: null,
            markers: [],
            visible_markers: [],
            clusterer: null,
            current_position: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-map {
    &, & > div {
        width: 100%;
        min-height: 100%;
    }
    .dg-info-window-content {
        .form-checkbox {
            display: none;
        }
    }
    .slider {
        &::-webkit-slider-runnable-track {
            background: $gray-color-dark;
        }
        &::-moz-range-track {
            background: $gray-color-dark;
        }
        &::-ms-track {
            background: $gray-color-dark;
        }
        &::-ms-fill-lower {
            background: $primary-color;
        }
    }
    #dg-radius-control.input-group {
        input.form-input {
            border-right: none;
        }
        .input-group-addon {
            background: #ffffff;
        }
    }
    &.selecting {
        .dg-info-window-content {
            .form-checkbox {
                display: block;
            }
        }
    }
}
</style>

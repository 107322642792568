<template>
    <input
        v-bind="input_props"
        :aria-autocomplete="autocomplete_id"
        :autocomplete="autocomplete_id"
        :autocapitalize="autocapitalize"
        v-on="$listeners"
        :class="{ [`input-${size}`]: true }"
        class="form-input"
    />
</template>

<script>
export default {
    name: "BaseInput",
    computed: {
        input_props () {
            const { size, ...props } = this.$props
            return props
        },
        autocomplete_id () {
            if(!this.autocomplete) return false
            if(this.autocomplete === "off") return this.autocomplete
            return `nibnut-${this.autocomplete}`
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        autocomplete: {
            type: String,
            default: ""
        },
        autocapitalize: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(none|sentences|words|characters)$/i),
            default: "sentences"
        },
        type: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(text|number|password|email|tel|time)$/i),
            default: "text"
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        step: {
            validator: prop => (prop === false) || !!prop,
            default: false
        },
        required: {
            type: Boolean,
            required: true
        },
        min: {
            type: Number,
            default: null
        },
        max: {
            type: Number,
            default: null
        },
        maxlength: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<template>
    <img src="@/assets/img/logo.png" :alt="alt" loading="lazy" decoding="async" class="img-responsive app-logo" />
</template>

<script>
import { mapState } from "vuex"

export default {
    computed: {
        ...mapState(["app_context"]),
        alt () {
            if(this.app_context.settings && this.app_context.settings) return this.app_context.settings.application_name
            return ""
        }
    }
}
</script>

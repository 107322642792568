<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <label
                    v-if="has_label"
                    class="form-label"
                    :for="identifier"
                >
                    <slot name="label"></slot>
                </label>
                <label
                    :class="{ 'text-gray': !display_value }"
                    class="form-label disabled-field"
                >
                    {{ display_value || translate("Unknown") }}
                </label>
            </slot>
        </template>
        <template v-slot:empty-value><slot name="empty-value"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <input-group>
            <base-dropdown
                ref="field"
                :id="identifier"
                :name="name"
                :value="value"
                :size="size"
                :idField="idField"
                :labelField="labelField"
                :disabledField="disabledField"
                :enabledField="enabledField"
                :emptyValue="emptyValue"
                :emptyLabel="emptyLabel"
                :options="available_options"
                :disabled="!editable || disabled"
                :required="required"
                @change="changed"
            />
        </input-group>
        <prompt
            v-if="prompting"
            v-bind="prompt_props"
            @cancel="cancel_prompt"
            @save="prompted"
        />
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import prompts from "@/nibnut/mixins/Prompts"

import FormGroup from "./FormGroup"
import InputGroup from "./InputGroup"
import BaseDropdown from "./BaseDropdown"

export default {
    name: "FormDropdown",
    mixins: [is_nibnut_component, prompts],
    components: {
        FormGroup,
        InputGroup,
        BaseDropdown
    },
    mounted () {
        this.update_display_value()
    },
    watch: {
        value: "update_display_value",
        options: "update_display_value"
    },
    methods: {
        changed (event) {
            if(this.editable) {
                const option = ((event.target.value === this.emptyValue) || (this.emptyValue === parseInt(event.target.value))) ? { [this.idField]: this.emptyValue, [this.labelField]: this.emptyLabel } : this.options.find(option => (option[this.idField] === event.target.value) || (option[this.idField] === parseInt(event.target.value)))
                if(this.adHoc && !option) {
                    // this.$emit("create", event.target.name)
                    this.prompt(
                        {
                            title: this.translate("New Value"),
                            message: this.translate("Enter the new value:"),
                            value: "",
                            ok: this.translate("Save")
                        },
                        "add_option"
                    )
                } else this.$emit("input", option[this.idField], event.target.name, option)
            }
        },
        update_display_value () {
            this.display_value = ""
            if(this.value !== this.emptyValue) {
                let chosen_option = this.options.find(option => option.id === this.value)
                if(!chosen_option) {
                    this.options.forEach(option => {
                        if(!chosen_option && option.sub_levels) {
                            chosen_option = option.sub_levels.find(sub_option => sub_option.id === this.value)
                        }
                    })
                }
                this.display_value = chosen_option ? chosen_option[this.labelField] : ""
            }
        },
        cancel_prompt () {
            if(!this.creating_new_option) {
                setTimeout(() => {
                    const node = this.$refs.field.$el.querySelector(`option[value='${this.value}']`)
                    this.$refs.field.$el.value = node.value
                }, 150)
                this.done_prompting()
            }
            this.creating_new_option = false
        },
        prompted (name) {
            this.creating_new_option = true
            this.$emit("create", name)
            this.done_prompting()
        }
    },
    computed: {
        has_label () {
            return this.has_slot("label")
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        available_options () {
            if(!this.adHoc) return this.options
            return [
                ...this.options,
                { [this.idField]: undefined, [this.labelField]: this.translate("New value...") }
            ]
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: { // object.idField value
            default: null
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "name"
        },
        disabledField: {
            type: String,
            default: "" // empty = no disabling
        },
        enabledField: {
            type: String,
            default: "" // empty = no enabling
        },
        emptyValue: {
            default: 0
        },
        emptyLabel: {
            type: String,
            default: ""
        },
        options: {
            type: Array,
            default () {
                return []
            }
        },
        adHoc: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: { // disable input field
            type: Boolean,
            default: false
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            display_value: "",
            creating_new_option: false
        }
    }
}
</script>

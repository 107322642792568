<template>
    <base-form
        :has-required-fields="true"
        @submit.prevent.stop="$emit('submit')"
        class="card"
    >
        <div class="card-header text-center">
            <app-logo class="mt-4 mb-8" />
            <div class="divider" :data-content="title"></div>
        </div>
        <div class="card-body">
            <div
                tabindex="0"
                @focus="loop_to_last"
            ></div>
            <slot></slot>
        </div>
        <div v-if="has_slot('footer')" class="modal-footer">
            <slot name="footer"></slot>
        </div>
        <div
            tabindex="0"
            @focus="loop_to_first"
        ></div>
    </base-form>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import AppLogo from "@/nibnut/components/AppLogo"
import BaseForm from "@/nibnut/components/BaseForm"

export default {
    name: "AuthenticationPanel",
    mixins: [is_nibnut_component],
    components: {
        AppLogo,
        BaseForm
    },
    methods: {
        loop_to_last () {
            const target = document.getElementById("last_focusable")
            if(!target) console.error("Unknown last focusable")
            else this.$nextTick(() => target.focus())
        },
        loop_to_first () {
            const target = document.getElementById("first_focusable")
            if(!target) console.error("Unknown first focusable")
            else this.$nextTick(() => target.focus())
        }
    },
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>

<template>
    <authentication-panel
        :title="$root.translate('Reset Your Password')"
        @submit="reset"
    >
        <div class="nibnut-field-group">
            <form-input
                id="first_focusable"
                ref="first_field"
                v-model="email"
                name="email"
                type="email"
                glyph="envelope"
                glyph-position="left"
                :error="has_error('email')"
                :required="true"
                :maybe-autofilled="true"
                @input="update_email"
                @blur="update_email"
            >
                <template v-slot:label v-if="!labelLess">{{ $root.translate("Email") }}</template>
            </form-input>
            <password-editor
                id="password"
                name="password"
                v-model="password"
                glyph="key"
                glyph-position="left"
                autocomplete="new-password"
                :error="has_error('password')"
                :required="true"
            >
                <template v-slot:label v-if="!labelLess">{{ $root.translate("New Password") }}</template>
            </password-editor>
        </div>
        <div class="text-center mt-8">
            <default-button
                flavor="link"
                size="sm"
                @click.prevent="goto('login')"
            >
                {{ $root.translate("Login") }}
            </default-button>
        </div>
        <div class="text-center mt-8">
            <feedback-button
                type="submit"
                :status="feedback_status"
                :waiting="loading"
                :single-use="false"
                color="primary"
            >
                <template v-slot:message>
                    <div
                        :class="{ 'text-success': (feedback_status === true), 'text-error': (feedback_status === false) }"
                    >
                        {{ feedback_message }}
                    </div>
                </template>
                <open-icon glyph="key" />
                {{ $root.translate("Reset Password") }}
            </feedback-button>
        </div>
    </authentication-panel>
</template>

<script>
import is_panel from "./IsPanel"

import AuthenticationPanel from "./AuthenticationPanel"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import PasswordEditor from "@/nibnut/components/Inputs/PasswordEditor"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import FeedbackButton from "@/nibnut/components/Buttons/FeedbackButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    mixins: [is_panel],
    components: {
        AuthenticationPanel,
        FormInput,
        PasswordEditor,
        DefaultButton,
        OpenIcon,
        FeedbackButton
    },
    methods: {
        reset () {
            setTimeout(() => {
                this.loading = true
                this.clear_all_errors()
                this.$store.dispatch("RESET_PASSWORD", {
                    token: this.$route.query["password-reset"],
                    email: this.email,
                    password: encodeURIComponent(this.password)
                }).then(response => {
                    this.$emit("input", "")
                    this.password = ""
                    this.cancel()
                }).catch(this.receive_error).then(() => {
                    this.loading = false
                })
            }, this.autofill_timeout) // need a timeout slightly longer than input field's debounce. Otherwise, Enter key happens before this.field is updated!
        },
        cancel () {
            window.location.search = ""
        }
    },
    data () {
        return {
            password: ""
        }
    }
}
</script>

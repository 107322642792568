<template>
    <span></span>
</template>

<script>
import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import string_utilities from "@/nibnut/mixins/StringUtilities"
import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

export default {
    name: "MapMarker",
    mixins: [misc_utilities, string_utilities, addl_profile_utilities],
    mounted () {
        this.maybe_bootsrap()
    },
    watch: {
        listing: "maybe_bootsrap",
        marker: "maybe_bootsrap"
    },
    methods: {
        toggle_selection () {
            this.$emit("toggle-selection", this.marker._dg_listing.id)
        },
        maybe_bootsrap () {
            if(this.map && window._nibnut_google_loader && this.marker) {
                if(!window._nibnut_google_map_popup) {
                    window._nibnut_google_map_popup = new window._nibnut_google_loader.maps.InfoWindow({
                        content: "",
                        maxWidth: 300
                    })
                    window._nibnut_google_map_popup.addListener("domready", function () {
                        if(this.content && this.toggle_selection) {
                            const input = this.content.getElementsByTagName("input")[0]
                            if(!!input && !input._nibnut_listening) {
                                input.addEventListener("click", this.toggle_selection)
                                input._nibnut_listening = true
                            }
                        }
                    })
                }
                if(this.marker._dg_listing.id === 20709) console.log("MARKER", { id: this.marker._dg_listing.id, line1: this.marker._dg_listing.line1 })
                const listing_size_display = (field, format = "0,0") => {
                    if(this.marker._dg_listing[field]) {
                        let content = "<div class='column col-auto'>"
                        content += `<span>${this.nibnut_filter("nibnut.number", [this.marker._dg_listing[field] / 100, format])}`
                        if(this.marker._dg_listing[`${field}_units`] === this.constants("units", "UNIT_SQFT").id) content += "ft<sup>2</sup>"
                        else if(this.marker._dg_listing[`${field}_units`] === this.constants("units", "UNIT_ACRES").id) content += "ac"
                        else if(this.marker._dg_listing[`${field}_units`] === this.constants("units", "UNIT_HECTARES").id) content += "ha"
                        else content += ">m<sup>2</sup>"
                        content += "</span>"
                        content += "</div>"
                        return content
                    }
                    return ""
                }
                const count_display = (field, glyph = "") => {
                    if(this.marker._dg_listing[field]) {
                        let content = "<div class='column col-auto'>"
                        content += `<i class='las la-${glyph}'></i>${this.nibnut_filter("nibnut.number", [this.marker._dg_listing[field], "0,0"])}`
                        content += "</div>"
                        return content
                    }
                    return ""
                }
                const marker = this.marker
                this.marker.addListener("click", () => {
                    if(marker._dg_listing.id === 20709) console.log("POPUP", { id: marker._dg_listing.id, line1: marker._dg_listing.line1, popup_content: this.popup_content })
                    let popup_content = "<div class='dg-info-window-content'>"

                    popup_content += "<label class='form-checkbox'>"
                    popup_content += `<input type="checkbox"${marker._selected ? " checked='checked'" : ""} />`
                    popup_content += "<i class='form-icon'></i>"
                    popup_content += `${this.translate("Share")}`
                    popup_content += "</label>"

                    popup_content += "<div class='columns mb-1'>"
                    popup_content += `<div class='column col-5'><img src="${marker._dg_listing.computed_poster_url}" loading="lazy" decoding="async" /></div>`
                    popup_content += "<div class='column col-7'>"
                    popup_content += "<h5 class='mb-1'>"
                    popup_content += `<a href="/listing/${marker._dg_listing.slug}" target="_blank">${this.nibnut_filter("nibnut.currency", [marker._dg_listing.price / 100, "0,00"])}</a>`
                    if(marker._dg_listing.price_changes_list.length) {
                        popup_content += `<span data-tooltip="${marker._dg_listing.price_changes_list.join("\n")}" class="tooltip tooltip-left ml-2"><i class='las la-info-circle'></i></span>`
                    }
                    popup_content += "</h5>"
                    if(marker._dg_listing.sale_price) {
                        popup_content += "<h6 class='text-gray text-uppercase text-small'>"
                        popup_content += `<a href="/listing/${marker._dg_listing.slug}" target="_blank">Sale price: ${this.nibnut_filter("nibnut.currency", [marker._dg_listing.sale_price / 100, "0,00"])}</a>`
                        popup_content += "</h6>"
                    }
                    popup_content += "<div class='columns no-wrap mb-1'>"
                    popup_content += listing_size_display("size")
                    popup_content += listing_size_display("land_size", "0,0.0")
                    if(marker._dg_listing.days_on_market) {
                        popup_content += `<div class='column col-auto'>${this.translate("1d:::{n}d", { n: this.nibnut_filter("nibnut.number", [marker._dg_listing.days_on_market, "0,0"]) }, marker._dg_listing.days_on_market)}</div>`
                    }
                    popup_content += "</div>"
                    popup_content += "<div class='columns no-wrap mb-1'>"
                    popup_content += count_display("computed_bathroom_count", "bath")
                    popup_content += count_display("computed_bedroom_count", "bed")
                    popup_content += count_display("garage_spots", "car")
                    popup_content += "</div>"
                    popup_content += "</div>"
                    popup_content += "</div>"
                    popup_content += `<a href="/listing/${marker._dg_listing.slug}" target="_blank"><strong>${this.address_one_line(marker._dg_listing)}</strong></a>`
                    popup_content += "<div class='text-small text-gray'>"
                    popup_content += this.translate("Property #{mls_id}", marker._dg_listing)
                    if(marker._dg_listing.relisted_on) popup_content += ` &bull; ${this.translate("Re-listed on {date}", { date: this.nibnut_filter("nibnut.date", [marker._dg_listing.relisted_on]) })}`
                    if(marker._dg_listing.last_sent_at && this.is_at_least_realtor) popup_content += ` &bull; ${this.translate("Last sent on {date}", { date: this.nibnut_filter("nibnut.date", [marker._dg_listing.last_sent_at]) })}`
                    popup_content += "</div>"
                    if(!marker._dg_listing.is_available) {
                        const mine = marker._dg_listing && !!marker._dg_listing.seller_realtor_ids && (marker._dg_listing.seller_realtor_ids.indexOf(this.realtorId) >= 0)
                        popup_content += `<span class="ribbon${mine}"><span>${(marker._dg_listing.pending_at ? this.translate("Pending") : this.translate("Sold"))}</span></span>`
                    }
                    popup_content += "</div>"
                    const template = document.createElement("template")
                    template.innerHTML = popup_content

                    window._nibnut_google_map_popup.setContent(template.content.firstChild)
                    window._nibnut_google_map_popup.toggle_selection = this.toggle_selection
                    window._nibnut_google_map_popup.open({ anchor: marker, shouldFocus: false })
                })
            }
        }
    },
    props: {
        map: {
            type: Object,
            required: true
        },
        marker: {
            type: Object,
            required: true
        },
        realtorId: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            popup_content: null
            // popup: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.gm-style img {
    max-width: 100%;
}
.dg-info-window-content {
    .columns {
        max-width: 100%;
    }
    a:not(.btn) {
        $link-color: $body-font-color;
        $link-color-dark: darken($link-color, 10%);
        $link-color-light: lighten($link-color, 10%);
        color: $link-color;

        &:focus,
        &:active,
        &.active {
            color: $link-color-dark;
        }
        @include hover-supported {
            color: $link-color-dark;
        }

        &:visited {
            color: $link-color-light;
        }
    }
    .tooltip.tooltip-left::after {
        transform: translate(0, 100%);
    }
}
</style>

<template>
    <authentication-panel
        :title="$root.translate('We will send you a link to reset password')"
        @submit="login"
    >
        <div class="nibnut-field-group">
            <form-input
                id="first_focusable"
                ref="first_field"
                v-model="email"
                name="email"
                type="email"
                glyph="envelope"
                glyph-position="left"
                :error="has_error('email')"
                :required="true"
                :maybe-autofilled="true"
                @input="update_email"
                @blur="update_email"
            >
                <template v-slot:label v-if="!labelLess">{{ $root.translate("Email") }}</template>
            </form-input>
        </div>
        <div class="text-center mt-8">
            <default-button
                flavor="link"
                size="sm"
                @click.prevent="goto('login')"
            >
                {{ $root.translate("Login") }}
            </default-button>
        </div>
        <div class="text-center mt-8">
            <feedback-button
                :id="can_signup ? false : 'last_focusable'"
                type="submit"
                :status="feedback_status"
                :waiting="loading"
                :single-use="false"
                color="primary"
            >
                <template v-slot:message>
                    <div
                        :class="{ 'text-success': (feedback_status === true), 'text-error': (feedback_status === false) }"
                    >
                        {{ feedback_message }}
                    </div>
                </template>
                <open-icon glyph="key" />
                {{ $root.translate("Recover Password") }}
            </feedback-button>
        </div>
    </authentication-panel>
</template>

<script>
import is_panel from "./IsPanel"

import AuthenticationPanel from "./AuthenticationPanel"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import FeedbackButton from "@/nibnut/components/Buttons/FeedbackButton"

export default {
    mixins: [is_panel],
    components: {
        AuthenticationPanel,
        FormInput,
        DefaultButton,
        OpenIcon,
        FeedbackButton
    },
    methods: {
        login () {
            setTimeout(() => {
                this.loading = true
                this.clear_all_errors()
                this.$store.dispatch("SEND_PASSWORD_RESET",
                    {
                        email: this.email
                    }
                ).then(data => {
                    this.feedback_status = true
                    this.feedback_message = data.message
                }).catch(this.receive_error).then(() => {
                    this.loading = false
                })
            }, this.autofill_timeout)
        }
    }
}
</script>

<template>
    <label
        :class="{ [`form-${type}`]: true, [`input-${size}`]: true }"
    >
        <input
            ref="field"
            v-bind="$props"
            v-on="$listeners"
        />
        <i class="form-icon"></i><slot></slot>
    </label>
</template>

<script>
export default {
    name: "BaseToggleInput",
    mounted () {
        this.toggle_indeterminate()
    },
    watch: {
        indeterminate: "toggle_indeterminate"
    },
    methods: {
        toggle_indeterminate () {
            const node = this.$el.querySelector("input[type='checkbox']")
            if(node) node.indeterminate = this.indeterminate
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        checked: {
            default: false
        },
        indeterminate: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(radio|checkbox)$/i),
            default: "radio"
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<template>
    <div class="tab-container">
        <ul
            v-if="!autoHide || (tabs.length > 1)"
            :class="{ 'tab-block': block, 'hide-lg': (tabs.length > 3), 'hide-md': (tabs.length <= 3) }"
            class="tab"
        >
            <li
                v-for="tab in tabs"
                :key="tab[idField]"
                :class="{ active: (tab[idField] === currentTabId) }"
                class="tab-item"
            >
                <div
                    class="d-flex align-items-center"
                >
                    <div class="flex-variable">
                        <a
                            href
                            @click.prevent="$emit('click', tab[idField])"
                        >
                            <slot name="tab" :tab="tab">
                                {{ tab[labelField] }}
                            </slot>
                        </a>
                    </div>
                    <div v-if="has_slot('tab-controls', { tab })" class="flex-static">
                        <slot name="tab-controls" :tab="tab"></slot>
                    </div>
                </div>
            </li>
        </ul>
        <div
            v-if="!autoHide || (tabs.length > 1)"
            :class="{ active: picking, 'show-lg': (tabs.length > 3), 'show-md': (tabs.length <= 3) }"
            class="dropdown hover-disabled"
        >
            <default-button
                flavor="link"
                :block="true"
                @click.prevent="maybe_toggle"
            >
                <div class="d-flex align-items-center">
                    <div class="flex-grow">
                        <slot name="tab" :tab="current_tab">
                            {{ current_tab[labelField] }}
                        </slot>
                    </div>
                    <div class="flex-static pl-2">
                        <open-icon glyph="caret-down" />
                    </div>
                </div>
            </default-button>
            <ul v-if="picking" ref="overlay" class="menu">
                <li
                    v-for="tab in tabs"
                    :key="tab[idField]"
                    :class="{ active: (tab[idField] === currentTabId) }"
                    class="tab-item"
                >
                    <div
                        class="d-flex align-items-center"
                    >
                        <div class="flex-variable">
                            <a
                                href
                                @click.prevent="pick(tab)"
                            >
                                <slot name="tab" :tab="tab">
                                    {{ tab[labelField] }}
                                </slot>
                            </a>
                        </div>
                        <div v-if="has_slot('tab-controls', { tab })" class="flex-static">
                            <slot name="tab-controls" :tab="tab"></slot>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div
            :class="{ 'px-1': !autoHide || (tabs.length > 1), 'pt-1': !autoHide || (tabs.length > 1) }"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

let outside_click_listener

export default {
    mixins: [is_nibnut_component],
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        maybe_toggle (event) {
            if(!this.$refs.overlay || !this.$refs.overlay.contains(event.target)) {
                if(this.picking) this.hide()
                else this.show()
            }
        },
        show () {
            this.picking = true
            this.$nextTick(() => {
                this.bind_listeners()
            })
        },
        hide () {
            this.$nextTick(() => {
                this.unbind_listeners()
            })
            this.picking = false
        },
        bind_listeners () {
            if(!outside_click_listener) {
                outside_click_listener = event => {
                    if(this.picking && this.is_outside_click(event)) this.hide()
                }
                document.addEventListener("click", outside_click_listener)
            }
        },
        unbind_listeners () {
            if(outside_click_listener) {
                document.removeEventListener("click", outside_click_listener)
                outside_click_listener = null
            }
        },
        is_outside_click (event) {
            return !(this.$el.isSameNode(event.target) || this.$el.contains(event.target) || (this.$refs.overlay && this.$refs.overlay.contains(event.target)))
        },
        pick (tab) {
            this.$emit("click", tab[this.idField])
            this.hide()
        }
    },
    computed: {
        current_tab () {
            return this.tabs.find(tab => tab[this.idField] === this.currentTabId) || {}
        }
    },
    props: {
        tabs: {
            type: Array,
            default () {
                return [] // { id: "", title: "" }
            }
        },
        currentTabId: {
            type: String,
            default: ""
        },
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "title"
        },
        block: {
            type: Boolean,
            default: true
        },
        autoHide: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            picking: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.tab-container {
    background-color: $light-color;
    & > .tab {
        & > .tab-item {
            margin-bottom: -$border-width;

            & > a {
                border: $border-width solid transparent;
            }

            &.active {
                & > a {
                    border-color: $border-color $border-color $light-color;
                }
            }
        }
    }
    & > .dropdown {
        & > .btn {
            height: auto;
            text-align: left;
        }
        & > .menu {
            width: 100%;
        }
    }
}
</style>

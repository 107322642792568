<template>
    <authentication-panel
        :title="$root.translate('Login')"
        @submit="login"
    >
        <div class="nibnut-field-group">
            <form-input
                id="first_focusable"
                ref="first_field"
                v-model="email"
                name="email"
                type="email"
                glyph="envelope"
                glyph-position="left"
                :error="has_error('email')"
                :required="true"
                :maybe-autofilled="true"
                @input="update_email"
                @blur="update_email"
            >
                <template v-slot:label v-if="!labelLess">{{ $root.translate("Email") }}</template>
            </form-input>
            <password-input
                id="password"
                v-model="password"
                type="password"
                name="password"
                glyph="key"
                glyph-position="left"
                :error="has_error('password')"
                :required="true"
                @blur="password=$event"
            >
                <template v-slot:label v-if="!labelLess">{{ $root.translate("Password") }}</template>
            </password-input>
        </div>
        <div class="text-center mt-8">
            <default-button
                flavor="link"
                size="sm"
                @click.prevent="goto('password')"
            >
                {{ $root.translate("Forgot Password?") }}
            </default-button>
        </div>
        <div class="text-center mt-8">
            <feedback-button
                :id="can_signup ? false : 'last_focusable'"
                type="submit"
                :status="feedback_status"
                :waiting="loading"
                :single-use="false"
                color="primary"
            >
                <template v-slot:message>
                    <div
                        :class="{ 'text-success': (feedback_status === true), 'text-error': (feedback_status === false) }"
                    >
                        {{ feedback_message }}
                    </div>
                </template>
                <open-icon glyph="unlock" />
                {{ $root.translate("Login") }}
            </feedback-button>
        </div>
    </authentication-panel>
</template>

<script>
import is_panel from "./IsPanel"

import AuthenticationPanel from "./AuthenticationPanel"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import PasswordInput from "@/nibnut/components/Inputs/PasswordInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import FeedbackButton from "@/nibnut/components/Buttons/FeedbackButton"

export default {
    mixins: [is_panel],
    components: {
        AuthenticationPanel,
        FormInput,
        PasswordInput,
        DefaultButton,
        OpenIcon,
        FeedbackButton
    },
    methods: {
        login () {
            const proceed = () => {
                this.loading = true
                this.clear_all_errors()
                this.$store.dispatch("LOGIN", {
                    email: this.email,
                    password: encodeURIComponent(this.password)
                }).then(() => {
                    this.$emit("input", "")
                    this.password = ""
                    this.$store.dispatch("UNREQUEST_LOGIN")
                }).catch(this.receive_error).then(() => {
                    this.loading = false
                })
            }
            setTimeout(() => {
                if((window.top === window.self) || !document.hasStorageAccess) proceed()
                else {
                    document.hasStorageAccess().then(accessible => {
                        if(accessible) proceed()
                        else {
                            if(navigator.permissions) {
                                navigator.permissions.query({ name: "storage-access" }).then(permission => {
                                    if((permission.state === "granted") || (permission.state === "prompt")) {
                                        document.requestStorageAccess().then(proceed)
                                    } else if(permission.state === "denied") {
                                        console.error("Storage Access denied.")
                                    } else {
                                        console.error(`Unknown Permission State: ${permission.state}`)
                                    }
                                }).catch(error => {
                                    console.error(`Could not access permission state. Error: ${error}`)
                                    document.requestStorageAccess().then(proceed).catch(error => {
                                        console.error(`Could not request storage access. Error: ${error}`)
                                        proceed()
                                    })
                                })
                            } else {
                                document.requestStorageAccess().then(proceed).catch(error => {
                                    console.error(`Could not request storage access. Error: ${error}`)
                                    proceed()
                                })
                            }
                        }
                    }).catch(error => {
                        console.error(`Could verify storage access. Error: ${error}`)
                        proceed()
                    })
                }
            }, this.autofill_timeout) // need a timeout slightly longer than input field's debounce. Otherwise, Enter key happens before this.field is updated!
        }
    },
    data () {
        return {
            password: ""
        }
    }
}
</script>

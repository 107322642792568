import colors from "./MapColors"

const {
    COLOR_LANDSCAPE,
    COLOR_BORDERS,
    COLOR_WATER,
    COLOR_LINE,
    COLOR_POINT_FILL,
    COLOR_SELECTED_POINT,
    COLOR_POINT_SOLD
} = colors

const COLORS = {
    BORDERS: COLOR_BORDERS,
    LANDSCAPE: COLOR_LANDSCAPE,
    LINE: COLOR_LINE,
    POINT: COLOR_SELECTED_POINT,
    POINT_FILL: COLOR_POINT_FILL,
    WATER: COLOR_WATER,
    POINT_SOLD: COLOR_POINT_SOLD
}

const POINT_MARKER_ICON_CONFIG = {
    url: "/img/pin_active.png"
}
const POINT_MARKER_SOLD_ICON_CONFIG = {
    url: "/img/pin_sold.png"
}

const LINE_SYMBOL_CONFIG = {
    path: "M 0,-2 0,2",
    strokeOpacity: 1,
    strokeWeight: 2,
    scale: 1
}

const LINE_PATH_CONFIG = {
    clickable: false,
    geodesic: false,
    strokeOpacity: 0,
    strokeColor: COLORS.LINE,
    icons: [
        {
            icon: LINE_SYMBOL_CONFIG,
            repeat: "10px"
        }
    ]
}

const DEFAULT_ZOOM = 15

const map_settings = {
    clickableIcons: false,
    streetViewControl: false,
    panControlOptions: false,
    gestureHandling: "cooperative",
    mapTypeControl: false,
    fullscreenControl: true,
    zoomControlOptions: {
        style: "SMALL"
    },
    zoom: DEFAULT_ZOOM,
    minZoom: 4,
    maxZoom: 17
}

export { map_settings, LINE_PATH_CONFIG, POINT_MARKER_SOLD_ICON_CONFIG, POINT_MARKER_ICON_CONFIG, DEFAULT_ZOOM }

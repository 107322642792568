export default {
    COLOR_POINT_SOLD: "#464855",
    COLOR_POINT: "#c50802",
    COLOR_POINT_FILL: "#c50802",
    COLOR_LANDSCAPE: "#dfdfdf",
    COLOR_BORDERS: "#333333",
    COLOR_LINE: "#c50802",
    COLOR_SELECTED_POINT: "#c50802",

    COLOR_PRIMARY: "#c50802",
    COLOR_SECONDARY: "#f4f5fa",
    COLOR_LIGHT: "#f0f0f0",
    COLOR_DARK: "#333333",
    COLOR_GRAY: "#dfdfdf",
    COLOR_LIGHT_GRAY: "#dcdcdc",
    COLOR_DARK_GRAY: "#c3c3c3"
}

import { Loader } from "@googlemaps/js-api-loader"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"

const google_map_loader = {
    mounted () {
        const apiKey = this.setting("maps_key")
        if(apiKey) {
            if(!window._nibnut_google_loader) {
                new Loader({
                    apiKey,
                    version: "weekly",
                    libraries: this.google_map_libraries
                }).load().then(google => {
                    window._nibnut_google_loader = google
                    this.load_google_objects()
                })
            } else this.load_google_objects()
        }
    },
    mixins: [misc_utilities],
    watch: {
        dataVersion: "refresh",
        mapConfig: "refresh"
    },
    methods: {
        refresh () {
        },
        load_google_objects () {
            this.refresh()
        },
        google_loaded () {
            return !!window._nibnut_google_loader
        }
    },
    props: {
        mapConfig: Object,
        dataVersion: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            google_map_libraries: []
        }
    }
}

export default google_map_loader

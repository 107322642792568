<template>
    <form-group
        v-bind="form_group_props"
        :class="{ 'full-height': (size === 'full') }"
        class="form-textbox"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <input-group>
            <base-textarea
                ref="field"
                :id="identifier"
                :name="name"
                :value="value"
                :placeholder="placeholder"
                :rows="rows"
                :aria-describedby="!!has_slot('hint')?`${identifier}-description`:false"
                :disabled="disabled"
                :required="required"
                @focus="maybe_autoselect"
                @touchstart="touchstart"
                @keyup="$emit('keyup', $event)"
                @keydown="keydown"
                @blur="blur"
                class="form-input"
            />
        </input-group>
    </form-group>
</template>

<script>
import debounce from "lodash/debounce"

import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"

import FormGroup from "./FormGroup"
import InputGroup from "./InputGroup"
import BaseTextarea from "./BaseTextarea"

export default {
    name: "FormTextbox",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        FormGroup,
        InputGroup,
        BaseTextarea
    },
    watch: {
        editable: "maybe_focus_field"
    },
    methods: {
        focus_field () {
            if(this.$refs.field) {
                const node = this.$refs.field.$el || this.$refs.field
                if(node.focus) node.focus()
                if(node.select) node.select()
            }
        },
        maybe_focus_field () {
            if(this.editable) setTimeout(this.focus_field, 200)
        },
        maybe_autoselect (event) {
            if(this.autoSelect) event.target.select()
        },
        keydown: debounce(function (event) {
            if(this.alphanumeric && this.iOS) this.touching = false
            if(this.live) this.$emit("input", event.target.value, this.name)
        }, 300),
        blur (event) {
            if(this.alphaNumeric && this.iOS) this.touching = false
            if(!this.live) this.$emit("input", event.target.value, this.name)
            this.$emit("blur", event.target.value, this.name) // can be useful for autofilled values... Use this to manually set your v-model's value
        },
        click_icon () {
            this.focus_field()
        }
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        rows () {
            if(this.size === "sm") return 1
            if(this.size === "md") return 3
            if(this.size === "lg") return 7
            return 0
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        required: {
            type: Boolean,
            required: true
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(sm|md|lg|full)$/i),
            default: "md"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },
        live: {
            type: Boolean,
            default: false
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>

<style lang="scss">
.form-textbox {
    &.full-height {
        height: 100%;
        display: flex;
        flex-direction: column;

        & > label, & > div {
            flex: 0 0 auto;
        }
        & > div.input-group {
            flex: 1 0 auto;

            textarea { height: 100%; }
        }
    }
}
</style>

<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <span v-if="value === trueValue">
                    <open-icon glyph="check-circle" /><slot></slot>
                </span>
                <span v-else class="text-gray">
                    <open-icon glyph="ban" /><slot></slot>
                </span>
            </slot>
        </template>
        <template v-slot:label><slot name="label"><div v-if="horizontal" class="d-invisible">{{ name }}</div></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>

        <default-toggle-input
            ref="field"
            :id="identifier"
            :type="type"
            :name="name"
            :value="value"
            :true-value="trueValue"
            :false-value="falseValue"
            :size="size"
            :aria-describedby="!!has_slot('hint') ? `${identifier}-description` : false"
            :disabled="!editable || disabled"
            v-on="$listeners"
        >
            <slot></slot>
        </default-toggle-input>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "./FormGroup"
import DefaultToggleInput from "./DefaultToggleInput"
import OpenIcon from "../OpenIcon"

export default {
    name: "FormToggleInput",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        DefaultToggleInput,
        OpenIcon
    },
    methods: {
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: false,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        }
    },
    props: {
        ...DefaultToggleInput.props,
        horizontal: {
            type: Boolean,
            default: false
        },
        disabled: { // disable input field
            type: Boolean,
            default: false
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
